<template>
  <div>
    <el-card style="animation: article 1s;">
      <!--Markdown样式、prism的行序号标记、tocbot的目录标记-->
      <div class="md line-numbers js-toc-content" v-viewer v-html="this.$store.state.headerInfo.content"></div>
      <div class="endInfo">
        <button class="btn-reward" type="button" @click="openReward">
          <i class="fa-solid fa-cup-straw"></i> 赞 赏
        </button>
      </div>
      <p style="color: #999;text-align: center;margin-top: 30px">三点几咧，饮茶先啦</p>
      <el-divider class="blog-divider"></el-divider>
      <div class="post-copyright">
        <ul class="list" data-v-292c4133="">
          <li data-v-292c4133=""><span>作者：</span>Iceburst <a class="" href="mailTo:1348188006@qq.com">（联系作者）</a></li>
          <li data-v-292c4133=""><span>发表时间：</span>{{ this.$store.state.headerInfo.createTime | dateFormat() }}</li>
          <li data-v-292c4133=""><span>最后修改：</span>{{ this.$store.state.headerInfo.updateTime | dateFormat() }}</li>
          <li data-v-292c4133=""><span>版权声明：</span>本站点采用<a data-v-292c4133="" href="https://creativecommons.org/licenses/by/4.0/"
                                         target="_blank"> 署名 4.0 国际 (CC BY 4.0) </a>创作共享协议。可自由转载、引用，并且允许商业性使用。但需署名作者且注明文章出处。
          </li>
        </ul>
      </div>
    </el-card>

    <el-card style="animation: article 1s;margin-top: 40px">
      <CommentList/>
    </el-card>
    <!--打赏二维码-->
    <div id="reward" :style="{display: isShow}" class="reward-wrap" @click="closeReward($event)">
      <div class="pop">
        <span class="head-text">请选择扶贫方式</span>
        <div class="list-wrap">
          <ul>
            <li>
              <span :class="[{'list-off' :isActive}, {'list-on': !isActive}]" @click="paySwitch">蓝色修改器</span>
            </li>
            <li>
              <span :class="[{'list-off' :!isActive}, {'list-on': isActive}]" @click="paySwitch">绿色修改器</span>
            </li>
          </ul>
        </div>
        <div class="img-wrap">
          <div style="width: 260px">
            <img v-show="payImgShow" :src="require('@/assets/img/Alipay.png')" alt="Alipay">
            <img v-show="!payImgShow" :src="require('@/assets/img/WeChat.png')" alt="WeChat">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import "../assets/js/prism"
import CommentList from "@/components/comment/CommentList";

export default {
  name: "blog",
  components: {CommentList},
  data() {
    return {
      article: null,
      isActive: false,
      isShow: "none",
      payImgShow: true,
    }
  },
  created() {
    this.getDetail();
  },
  mounted() {

  },
  methods: {
    //获取文章详情,存入store
    getDetail() {
      this.$request.get("blog/detail", {
            params: {
              id: this.$route.params.id
            }
          }
      ).then((res) => {
        this.article = res.data
        //提交文章信息
        this.$store.commit('setHeaderInfo', this.article)
        this.$nextTick(()=>{
          Prism.highlightAll();
          //设置渲染状态为true
          this.$store.commit('setRenderOver', true)
        })
      })
    },
    //通过按钮打开打赏页
    openReward() {
      if (this.isShow === "none") {
        this.isShow = "flex"
      }
    },
    //点击其他区域关闭打赏页
    closeReward(e) {
      if (e.target.id === "reward" && e.target.style.display !== "none") {
        this.isShow = "none"
      }
    },
    //切换打赏二维码和样式
    paySwitch() {
      this.isActive = !this.isActive
      this.payImgShow = !this.payImgShow
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setRenderOver', false)
    this.$store.commit('setHeaderInfo', "")
    // 从文章页面路由到其它页面时，销毁当前组件的同时，要销毁tocbot实例
    // 否则tocbot一直在监听页面滚动事件，而文章页面的锚点已经不存在了，会报"Uncaught TypeError: Cannot read property 'className' of null"
    tocbot.destroy()
    next()
  },

}
</script>

<style scoped>
/*!*解决最下方的评论展开时表情框被隐藏的问题*!*/
/*/deep/ .el-card {*/
/*  overflow: visible;*/
/*}*/

/deep/ .el-card__body {
  padding: 40px 50px;
}

/*打赏样式*/
.btn-reward {
  /*布局*/
  box-sizing: border-box;
  padding: 10px 20px;
  /*文本*/
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-stretch: 100%;
  font-weight: bold;
  line-height: 21px;
  /*外观*/
  color: rgb(255, 255, 255);
  box-shadow: 0 2px 6px 0 rgb(114 124 245 / 50%);
  border: 1px solid transparent;
  background-color: rgba(114, 124, 245, 0.5);
  outline: rgb(255, 255, 255) none 0;
  /*过渡动画*/
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  /*其他*/
  text-rendering: auto;
  writing-mode: horizontal-tb;
  border-radius: 28px;
  overflow-wrap: break-word;
  user-select: none;

}

.btn-reward:hover {
  color: #fff;
  background-color: rgba(78, 91, 242, 0.5);
  cursor: url(/src/assets/img/Hatsune2.png), auto;

}

.endInfo {
  margin: 70px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.reward-wrap {
  /*布局*/
  background-color: rgb(0 0 0 / 40%);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  z-index: 100;
  /*文本*/
  font-family: CustomFonts, Inter, "Noto: Sans: SC", "Microsoft: YaHei", sans-serif;
  line-height: 24px;
  tab-size: 4;
  text-align: center;
  text-size-adjust: 100%;
}

.pop {
  /*布局*/
  box-sizing: border-box;
  display: block;
  margin-left: 40px;
  margin-right: 40px;
  padding: 1.8rem;
  /*外观*/
  color: rgb(107, 114, 128);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  /*其他*/
  border-radius: 12px;
}

.head-text {
  /*布局*/
  box-sizing: border-box;
  display: block;
  margin-bottom: 12px;
  /*文本*/
  font-family: CustomFonts, Inter, "Noto: Sans: SC", "Microsoft: YaHei", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  tab-size: 4;
  text-align: center;
  text-size-adjust: 100%;
}

.list-wrap {
  box-sizing: border-box;
  display: block;
  /*文本*/
  font-family: CustomFonts, Inter, "Noto: Sans: SC", "Microsoft: YaHei", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  tab-size: 4;
  text-align: center;
}

.list-wrap ul {
  /*布局*/
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 10px;
  padding: 0px;
  /*其他*/
  list-style: none outside none;
}

.list-wrap li {
  margin-right: 10px;
}

.list-on {
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: rgb(28, 100, 242);
  color: rgb(28 100 242);
}

.list-off {
  border-color: transparent;
}

span.list-off:hover {
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: rgb(209 213 219);
  color: rgb(75 85 99);
}

.list-wrap span {
  /*布局*/
  box-sizing: border-box;
  display: inline-block;
  padding: 16px;
  /*外观*/
  border-bottom-width: 3px;
  /*其他*/
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  list-style: none outside none;
}

.img-wrap {
  width: 100%;
  display: flex;
}

.img-wrap img {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
}

.post-copyright {
  line-height: 25px;
  font-size: 16px;
  position: relative;
  margin: 2rem 0 .5rem;
  padding: .5rem .8rem;
  border: 1px solid #eee;
  transition: box-shadow .3s ease-in-out;
  background-color: rgb(252,255,245);
}
.post-copyright li {
  list-style-type: none;
  margin: 0 0 .3em 1em;
  color: #565453;
}
.post-copyright ul {
  padding: 0;
}
.post-copyright a {
  word-break: break-word;
  color: #99a9bf;
}
.post-copyright span {
  color: #59b1f5;
}

.blog-divider {
  margin: 40px 0 60px 0;
}
</style>